export default {
    lang: "Русский",
    header: {
        nav_about: "О нас",
        nav_tours: "туры",
        nav_gallery: "Галерея",
        nav_feeds: "Отзывы",
        nav_contact: "контакты",
    },
    intro: {
        title: "Aviafly - Откройте мир вместе с нами!",
        desc: "Наша команда профессионалов готова предложить вам лучшие условия путешествия и создать незабываемые впечатления.",
    },
    tours: {
        title: "Мы предлагаем",
        desc: "Лучшие предложения для незабываемых путешествий. Выбирайте направления и отдыхайте с комфортом и удовольствием!",

        tourcard1: {
            title: "Туры по Европе",
            desc: "Исследуйте богатую культуру Европы – от величественных замков Германии до модных улиц Парижа.",
            duration: "5 дней",
        },
        tourcard2: {
            title: "Туры по Турции",
            desc: "Погрузитесь в историю и красоту Турции – от древнего Стамбула до пляжей Анталии.",
            duration: "12 дней",
        },
        tourcard3: {
            title: "Туры по ОАЭ",
            desc: "Откройте для себя роскошь и футуристическую архитектуру Дубая и Абу-Даби.",
            duration: "4 дней",
        },
        tourcard4: {
            title: "Туры в Египет",
            desc: "Познакомьтесь с древней историей Египта – от пирамид Гизы до курортов Красного моря.",
            duration: "5 дней",
        },
        tourcard5: {
            title: "Туры на Мальдивы",
            desc: "Насладитесь райскими пляжами Мальдив – идеальное место для спокойного отдыха и дайвинга.",
            duration: "7 дней",
        },
        tourcard6: {
            title: "Туры в Таиланд",
            desc: "Путешествуйте по экзотическим местам Таиланда – от пляжей Пхукета до культурного наследия Бангкока.",
            duration: "4 дней",
        },
    },
    about: {
        title: "О нас",
        subtitle: "Познавайте мир вместе с Aviafly!",
        desc1: "Агентство Aviafly — это ваш проводник в увлекательный мир приключений и открытий.",
        desc2: "Мы специализируемся на создании незабываемых путешествий по всему миру, организации экскурсий, бронировании гостиниц и транспорта. Наша цель — сделать ваш отдых максимально комфортным и запоминающимся.",
        desc3: "Независимо от того, ищете ли вы расслабляющий пляжный отдых, захватывающие экскурсии по культурным достопримечательностям или приключения на природе, мы поможем вам воплотить ваши мечты в реальность.",

        cons1: "Доверие клиентов.",
        cons2: "Большой выбор разнообразных туров.",
        cons3: "Обслуживание на высшем уровне.",
        cons4: "Безупречные отзывы клиентов.",
    },
    contacts: {
        title: "Контакты",
        desc: "Хотите сотрудничать? Свяжитесь с нами",
        infos: "Данные:",
        socials: "Мы в соц.сетях:",
        address: "Ташкент, Яккасарайский район, Бобур МФЙ, 69/8 дом",
        address__title: "Адрес",
        phone__title: "Телефон",
        fax__title: "Факс",
        ceo: "Директор",
    },
    feeds: {
        title: "Отзывы наших клиентов",
        desc: "Реальные впечатления и эмоции тех, кто доверил нам свои путешествия",
        feed1: {
            name: "Мухаммадумид Олимжонов",
            desc: "Поздравляю с Новым годом! Желаю вам успехов в делах, побольше довольных клиентов с большими заказами! Огромное спасибо, что уже два года вы помогаете нам наслаждаться красотой мира. С новым годом! Пусть каждый месяц в новом году мы летаем с вашей помощью!",
            position: "Айти специалист",
        },
        feed2: {
            name: "Джавохир Абдуллаев",
            desc: "Все было великолепно: от трансфера до самой поездки. Вы учли каждую деталь. Это было лучшее и самое безопасное путешествие. Отели в Стамбуле и Бодруме были замечательные. Хабиба, спасибо большое. И спасибо за вашу заботу и внимание даже на расстоянии. Мы с мужем это очень ценим!",
            position: "Финансовый специалист",
        },
        feed3: {
            name: "Эвелина Абдуразакова",
            desc: "Хотели сразу поблагодарить вас! Все было не просто отлично, но и ваше терпение очень помогло. Да и вы сами замечательные! После такого опыта даже думать не хочется о других турагентствах. У вас замечательная работа, и вы действительно делаете людей счастливыми!",
            position: "Мобилограф",
        },
    },
    gallery: {
        title: "Наша Галерея",
        desc: "Самые яркие моменты путешествий, организованных нами. Откройте для себя удивительные места и вдохновляйтесь на новые приключения",
    },
    leaveapp: {
        name: "Ваше Ф.И.О. *",
        phone: "Ваш номер телефона *",
        email: "Ваша почта *",
        comment: "Сообщение обращения *",
        send: "Отправить",
        success: "Ваша заявка успешно отправлена. Наши специалисты уже приступили к обработке и в скором времени свяжутся с вами!",
        fail: "К сожалению ваша заявка не отправлена из-за технических сбоев. Пожалуйста попробуйте позже или выходите на прямой контакт, просим прощения за причиненные неудобства!",
        wait: "Пожалуйста ожидайте!",
    },
    footer: {
        desc: "Агентство, успешно занимающееся туроператорской деятельностью. Компания с отличной репутацией, богатым наследием и многообещающим будущим!",
        address: "Яккасарай р-н., Бобур МФЙ, 69/8",
        workhour: "График работы 24/7",
        copyright: "Все права защищены",
        company: "Aviafly - откройте мир вместе с нами!",
    },
    common: {
        leaveapp: "Оставить заявку",
        leaveappdesc: "Заполните поля ниже и оставьте заявку, наши специалисты свяжутся с вами чтобы обсудить дальнейшие действия",
        leaveapp2: "Подать заявку",
        from: "от",
        book: "Забронировать",
    },
};
