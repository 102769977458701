var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"tp-popcontact",attrs:{"id":"button-form-follower"},on:{"click":() => {
            _vm.openModal({
                title: _vm.$t('common.leaveapp'),
                url: '/form-main',
                form: 'Форма кнопки поддержки',
            });
        }}},[_c('img',{staticClass:"tp-popcontact__image",attrs:{"src":require("@/assets/icons/icon-cphone.svg"),"alt":"contact"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }