import { render, staticRenderFns } from "./compLanguage.vue?vue&type=template&id=4ef2204e&scoped=true&"
import script from "./compLanguage.vue?vue&type=script&lang=js&"
export * from "./compLanguage.vue?vue&type=script&lang=js&"
import style0 from "./compLanguage.vue?vue&type=style&index=0&id=4ef2204e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ef2204e",
  null
  
)

export default component.exports