export default {
    lang: "O'zbekcha",
    header: {
        nav_about: "Aviafly",
        nav_tours: "Turlar",
        nav_gallery: "Galereya",
        nav_feeds: "Sharhlar",
        nav_contact: "Bog'lanish",
    },
    intro: {
        title: "Aviafly - Biz bilan dunyoni kashf qiling!",
        desc: "Bizning professional jamoamiz sizga eng yaxshi sayohat sharoitlarini taqdim etishga va unutilmas taassurotlar yaratishga tayyor.",
    },
    tours: {
        title: "Biz taklif qilamiz",
        desc: "Unutilmas sayohatlar uchun eng yaxshi takliflar. Yo'nalishlarni tanlang va qulaylik va zavq bilan dam oling!",

        tourcard1: {
            title: "Yevropaga sayohatlar",
            desc: "Yevropaning boy madaniyatini o'rganing – Germaniyaning ulug'vor qal'alari va Parijning zamonaviy ko'chalarigacha.",
            duration: "5 kun",
        },
        tourcard2: {
            title: "Turkiyaga sayohatlar",
            desc: "Qadimiy Istanbuldan Antaliya plyajlarigacha bo'lgan Turkiyaning tarixiy va go'zalligiga sho'ng'ing.",
            duration: "12 kun",
        },
        tourcard3: {
            title: "BAAga sayohatlar",
            desc: "Dubay va Abu-Dabi shaharlarining hashamatli va futuristik arxitekturasini kashf eting.",
            duration: "4 kun",
        },
        tourcard4: {
            title: "Misrga sayohatlar",
            desc: "Giza piramidalari va Qizil dengiz kurortlaridan Misrning qadimiy tarixini kashf eting.",
            duration: "5 kun",
        },
        tourcard5: {
            title: "Maldiv orollariga sayohatlar",
            desc: "Maldivlarning jannatmonand plyajlaridan rohatlaning – dam olish va sho'ng'in uchun ideal joy.",
            duration: "7 kun",
        },
        tourcard6: {
            title: "Tailandga sayohatlar",
            desc: "Tailandning ekzotik joylariga sayohat qiling – Puket plyajlaridan Bangkokning madaniy merosigacha.",
            duration: "4 kun",
        },
    },
    about: {
        title: "Biz haqimizda",
        subtitle: "Aviafly bilan dunyoni kashf qiling!",
        desc1: "Aviafly agentligi — bu sizni sarguzashtlar va kashfiyotlar olamiga yetaklovchi yo'lboshchi.",
        desc2: "Biz butun dunyo bo'ylab unutilmas sayohatlar, ekskursiyalar tashkil etish, mehmonxonalar va transportni bron qilishga ixtisoslashganmiz. Bizning maqsadimiz sizning dam olishingizni maksimal darajada qulay va esda qolarli qilishdir.",
        desc3: "Agar siz dam olish uchun plyajda dam olishni, madaniy diqqatga sazovor joylar bo'ylab hayajonli ekskursiyalarni yoki tabiat qo'ynidagi sarguzashtlarni izlayotgan bo'lsangiz, biz sizning orzularingizni ro'yobga chiqarishga yordam beramiz.",

        cons1: "Mijozlarning ishonchi.",
        cons2: "Turli xil sayohat turlarining katta tanlovi.",
        cons3: "Yuqori darajadagi xizmat ko'rsatish.",
        cons4: "Mijozlarning mukammal sharhlari.",
    },
    contacts: {
        title: "Bog'lanish",
        desc: "Hamkorlik qilmoqchimisiz? Biz bilan bog'laning",
        infos: "Ma'lumotlar:",
        socials: "Biz ijtimoiy tarmoqlarda:",
        address: "Toshkent, Yakkasaroy tumani, Bobur MFY, 69/8 uy",
        address__title: "Manzil",
        phone__title: "Telefon",
        fax__title: "Faks",
        ceo: "Rahbar",
    },
    feeds: {
        title: "Mijozlarimizning sharhlari",
        desc: "Bizga o'z sayohatlarini ishonib topshirganlarning haqiqiy taassurotlari va his-tuyg'ulari",
        feed1: {
            name: "Muhammadumid Olimjonov",
            desc: "Yangi yilingiz bilan tabriklayman! Ishingizda muvaffaqiyatlar, ko'proq katta buyurtmali mijozlar tilayman! Sizga dunyo go'zalligidan bahramand bo'lishimizga ikki yildan beri yordam berganingiz uchun katta rahmat. Yangi yilingiz bilan! Har oyda sizning yordamingiz bilan uchib yuring!",
            position: "IT mutaxassisi",
        },
        feed2: {
            name: "Javohir Abdullaev",
            desc: "Hammasi ajoyib edi: transferdan sayohatning o'ziga qadar. Har bir detalni hisobga olgansiz. Bu eng yaxshi va xavfsiz sayohat edi. Istanbul va Bodrumdagi mehmonxonalar ajoyib edi. Xabiba, katta rahmat. Va uzoqdan bo'lsa ham, sizning g'amxo'rligingiz va e'tiboringiz uchun rahmat. Men va erim buni juda qadrlaymiz!",
            position: "Moliyaviy mutaxassis",
        },
        feed3: {
            name: "Evelina Abdurazakova",
            desc: "Sizga darhol minnatdorchilik bildirmoqchi edik! Hammasi nafaqat ajoyib, balki sabringiz juda yordam berdi. Va siz o'zingiz ham ajoyibsiz! Shunday tajribadan so'ng boshqa sayohat agentliklari haqida o'ylash ham kelmaydi. Sizning ishingiz ajoyib va siz haqiqatan ham odamlarni baxtli qilasiz!",
            position: "Mobilograf",
        },
    },
    gallery: {
        title: "Bizning Galereya",
        desc: "Biz tomonidan tashkil etilgan sayohatlarning eng yorqin lahzalari. Ajoyib joylarni kashf qiling va yangi sarguzashtlarga ilhom oling",
    },
    leaveapp: {
        name: "F.I.SH. *",
        phone: "Telefon raqamingiz *",
        email: "Elektron pochtangiz",
        comment: "Murojaat xabari *",
        send: "Yuborish",
        success: "Sizning arizangiz muvaffaqiyatli yuborildi. Bizning mutaxassislarimiz uni ko'rib chiqishni boshladilar va tez orada siz bilan bog'lanadilar!",
        fail: "Afsuski, texnik nosozliklar tufayli arizangiz yuborilmadi. Iltimos, keyinroq qayta urinib ko'ring yoki bevosita bog'laning, noqulayliklar uchun uzr so'raymiz!",
        wait: "Iltimos, kuting!",
    },
    footer: {
        desc: "Muvaffaqiyatli sayyohlik operatori faoliyatini olib borayotgan agentlik. Ajoyib obro'ga ega kompaniya, boy meros va istiqbolli kelajak!",
        address: "Yakkasaroy tumani, Bobur MFY, 69/8",
        workhour: "Ish vaqti 24/7",
        copyright: "Barcha huquqlar himoyalangan",
        company: "Aviafly - biz bilan dunyoni kashf qiling!",
    },
    common: {
        leaveapp: "Ariza qoldirish",
        leaveappdesc: "Quyidagi maydonlarni to'ldiring va arizani yuboring, mutaxassislarimiz siz bilan bog'lanib, keyingi harakatlarni muhokama qilishadi",
        leaveapp2: "Ariza topshirish",
        from: "dan",
        book: "Bron qilish",
    },
};
